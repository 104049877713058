import { useEffect, useState } from "react";
import "./App.css";

import { CharacterTable } from "./CharacterTable";
import { ResourceTable } from "./ResourceTable";
import { CharacterInfo, ItemInfo, getInfo } from "./api";
import { config } from "./config";
import { Resource } from "./models/resources";

function App() {
  const [url, updateUrl] = useState(config.apiUrl);
  const [equipments, updateEquipments] = useState<Resource[]>([]);
  const [bds, updateBDs] = useState<Resource[]>([]);
  const [notes, updateNotes] = useState<Resource[]>([]);
  const [characters, updateCharacters] = useState<CharacterInfo[]>([]);
  const [elephs, updateElephs] = useState<ItemInfo[]>([]);
  const [minSet, updateMinSet] = useState(
    parseInt(JSON.parse(window.localStorage.getItem("minSet") ?? "3"))
  );
  const [enableMaxSet, updateEnableMaxSet] = useState(true);

  useEffect(() => {
    window.localStorage.setItem("minSet", `${minSet}`);
  }, [minSet]);

  useEffect(() => {
    config.apiUrl = url;
    getInfo().then(([equipments, bds, notes, characters, elephs]) => {
      updateEquipments(equipments);
      updateBDs(bds);
      updateNotes(notes);
      updateCharacters(characters);
      updateElephs(elephs);
    });
  }, [url]);

  return (
    <div>
      <label>
        API URL
        <input value={url} onChange={(ev) => updateUrl(ev.target.value)} />
      </label>
      <label>
        Minimum number of sets
        <input
          value={minSet}
          onChange={(ev) => updateMinSet(+ev.target.value)}
          type={"number"}
          min={1}
          max={10}
          step={1}
        />
      </label>
      <label>
        Enable max set
        <input
          type={"checkbox"}
          checked={enableMaxSet}
          onChange={(ev) => updateEnableMaxSet(ev.target.checked)}
        />
      </label>
      <button
        onClick={() => {
          getInfo().then(([equipments, bds, notes, characters, elephs]) => {
            updateEquipments(equipments);
            updateBDs(bds);
            updateNotes(notes);
            updateCharacters(characters);
            updateElephs(elephs);
          });
        }}
      >
        Refresh
      </button>
      <ResourceTable
        data={equipments}
        minSet={minSet}
        enableMaxSet={enableMaxSet}
      />
      <ResourceTable data={bds} minSet={minSet} enableMaxSet={enableMaxSet} />
      <ResourceTable data={notes} minSet={minSet} enableMaxSet={enableMaxSet} />
      <div style={{ maxWidth: 600 }}>
        <CharacterTable characters={characters} elephs={elephs} />
      </div>
    </div>
  );
}

export default App;
