import axios from "axios";
import { config } from "./config";
import {
  AllEquipmentCategories,
  BD,
  Equipment,
  Note,
  schoolNameJpToEn,
} from "./models/resources";

export async function getInfo(): Promise<
  [Equipment[], BD[], Note[], CharacterInfo[], ItemInfo[]]
> {
  const resp = await axios.get(config.apiUrl);
  const info = resp.data as Info;
  const equipments = (
    info?.equipments.filter(
      (x) =>
        AllEquipmentCategories.includes(x.Category) && !x.BoundCharacterServerId
    ) ?? []
  ).map((v) => new Equipment(v.Category, v.Tier, v.StackCount));
  const bds = (
    info?.items.filter((x) => {
      if (!x.Name.includes("戦術教育BD\\n")) return false;
      return true;
    }) ?? []
  ).map((v) => {
    const category = schoolNameJpToEn(v.Name.split("（")[1].split("）")[0]);
    return new BD(category, v.Quality - 1, v.StackCount);
  });
  const notes = (
    info?.items.filter((x) => {
      if (!x.Name.includes("技術ノート\\n")) return false;
      return true;
    }) ?? []
  ).map((v) => {
    const category = schoolNameJpToEn(v.Name.split("（")[1].split("）")[0]);
    return new Note(category, v.Quality - 1, v.StackCount);
  });
  return [
    equipments,
    bds,
    notes,
    info.characters,
    info.items.filter((v) => v.Category === "SecretStone"),
  ];
}

export interface Info {
  equipments: EquipmentInfo[];
  items: ItemInfo[];
  characters: CharacterInfo[];
}

export interface EquipmentInfo {
  UniqueId: number;
  ServerId: number;
  Tier: number;
  Name: string;
  Category: string;
  StackCount: number;
  BoundCharacterServerId?: number;
}

export interface ItemInfo {
  UniqueId: number;
  ServerId: number;
  StackCount: number;
  Quality: number;
  Category: string;
  Name: string;
}

export interface CharacterInfo {
  UniqueId: number;
  ServerId: number;
  StarGrade: number;
  Level: number;
  FavorRank: number;
  FavorExp: number;
  PublicSkillLevel: number;
  ExSkillLevel: number;
  PassiveSkillLevel: number;
  ExtraPassiveSkillLevel: number;
  Name: string;
  DevName: string;
  CollectionTexturePath: string;
  Weapon?: WeaponInfo;
}

export interface WeaponInfo {
  UniqueId: number;
  StarGrade: number;
  Level: number;
}
