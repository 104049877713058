export type School = { enName: string; jpName: string };
export const AllSchools: School[] = [
  { enName: "Hyakkiyako", jpName: "百鬼夜行" },
  { enName: "RedWinter", jpName: "レッドウィンター" },
  { enName: "Trinity", jpName: "トリニティ" },
  { enName: "Gehenna", jpName: "ゲヘナ" },
  { enName: "Abydos", jpName: "アビドス" },
  { enName: "Millennium", jpName: "ミレニアム" },
  { enName: "Arius", jpName: "アリウス" },
  { enName: "Shanhaijing", jpName: "山海経" },
  { enName: "Valkyrie", jpName: "ヴァルキューレ" },
];

export function schoolNameEnToJp(name: string): string {
  return AllSchools.find((v) => v.enName === name)?.jpName ?? "";
}

export function schoolNameJpToEn(name: string): string {
  return AllSchools.find((v) => v.jpName === name)?.enName ?? "";
}

export const AllEquipmentCategories = [
  "Badge",
  "Bag",
  "Charm",
  "Gloves",
  "Hairpin",
  "Hat",
  "Necklace",
  "Shoes",
  "Watch",
];
export interface Resource {
  count: number;

  requirementPerSet(): number;
  maxSets(): number;
  iconUrl(): string;
  categoryName(): string;
  tierName(): string;
  tierNameCompare(a: string, b: string): number;
}

export class Equipment implements Resource {
  constructor(
    public category: string,
    public tier: number,
    public count: number
  ) {}
  public requirementPerSet(): number {
    return {
      1: 1,
      2: 40,
      3: 45,
      4: 50,
      5: 55,
      6: 65,
      7: 55,
      8: 40,
      9: 50,
    }[this.tier] as number;
  }
  public maxSets(): number {
    return {
      Badge: 3,
      Bag: 3,
      Charm: 3,
      Gloves: 4,
      Hairpin: 3,
      Hat: 4,
      Necklace: 3,
      Shoes: 4,
      Watch: 4,
    }[this.category] as number;
  }
  iconUrl(): string {
    return `https://schale.gg/images/equipment/icon/equipment_icon_${this.category.toLowerCase()}_tier${
      this.tier
    }.webp`;
  }
  categoryName(): string {
    return this.category;
  }
  tierName(): string {
    return `T${this.tier}`;
  }
  tierNameCompare(a: string, b: string): number {
    return parseInt(a.slice(1)) - parseInt(b.slice(1));
  }
}

export class BD implements Resource {
  constructor(
    public category: string,
    public tier: number,
    public count: number
  ) {}

  public requirementPerSet(): number {
    return {
      0: 30,
      1: 30,
      2: 30,
      3: 8,
    }[this.tier] as number;
  }
  public maxSets(): number {
    return {
      Hyakkiyako: 2,
      RedWinter: 2,
      Trinity: 3,
      Gehenna: 3,
      Abydos: 2,
      Millennium: 3,
      Arius: 2,
      Shanhaijing: 2,
      Valkyrie: 2,
    }[this.category] as number;
  }
  iconUrl(): string {
    return `https://schale.gg/images/item/icon/item_icon_material_exskill_${this.category.toLowerCase()}_${
      this.tier
    }.webp`;
  }
  categoryName(): string {
    return schoolNameEnToJp(this.category);
  }
  tierName(): string {
    return {
      0: "初級",
      1: "中級",
      2: "上級",
      3: "最上級",
    }[this.tier] as string;
  }
  tierNameCompare(a: string, b: string): number {
    const map: Record<string, number> = {
      初級: 0,
      中級: 1,
      上級: 2,
      最上級: 3,
    };
    return map[a] - map[b];
  }
}

export class Note implements Resource {
  constructor(
    public category: string,
    public tier: number,
    public count: number
  ) {}

  public requirementPerSet(): number {
    return {
      0: 75,
      1: 75,
      2: 75,
      3: 60,
    }[this.tier] as number;
  }
  public maxSets(): number {
    return {
      Hyakkiyako: 2,
      RedWinter: 2,
      Trinity: 3,
      Gehenna: 3,
      Abydos: 2,
      Millennium: 3,
      Arius: 2,
      Shanhaijing: 2,
      Valkyrie: 2,
    }[this.category] as number;
  }
  iconUrl(): string {
    return `https://schale.gg/images/item/icon/item_icon_skillbook_${this.category.toLowerCase()}_${
      this.tier
    }.webp`;
  }
  categoryName(): string {
    return schoolNameEnToJp(this.category);
  }
  tierName(): string {
    return {
      0: "初級",
      1: "中級",
      2: "上級",
      3: "最上級",
    }[this.tier] as string;
  }
  tierNameCompare(a: string, b: string): number {
    const map: Record<string, number> = {
      初級: 0,
      中級: 1,
      上級: 2,
      最上級: 3,
    };
    return map[a] - map[b];
  }
}
