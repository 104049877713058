import lodash from "lodash";
import { useEffect, useState } from "react";
import { Resource } from "./models/resources";
export function ResourceTable(
  props: { data: Resource[]; minSet: number; enableMaxSet: boolean } = {
    data: [],
    minSet: 3,
    enableMaxSet: true,
  }
): JSX.Element {
  const [resourceMap, updateResourceMap] = useState<Map<string, Resource>>(
    new Map()
  );
  useEffect(() => {
    const resources = new Map<string, Resource>();
    props.data.forEach((x) => {
      resources.set(`${x.categoryName()}:${x.tierName()}`, x);
    });
    updateResourceMap(resources);
    console.log(resources);
  }, [props.data]);

  const extractKey = (data: Map<string, Resource>, idx: number) => {
    const v: Resource = data.values().next().value;
    return lodash.uniq(
      new Array(...data.keys())
        .map((key) => key.split(":")[idx])
        .sort(v.tierNameCompare)
    );
  };
  const getBGColor = (data: Resource | undefined, minSet: number) => {
    if (!data) {
      return "white";
    }
    const currentSets = Math.floor(data.count / data.requirementPerSet());
    if (props.enableMaxSet && currentSets >= data.maxSets()) {
      return "rgba(0, 0, 0, 0.2)";
    }
    if (currentSets >= minSet) {
      return "rgba(0, 255, 0, 0.1)";
    }
    return "rgba(255, 0, 0, 0.4)";
  };
  return (
    <div>
      {resourceMap.size > 0 ? (
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>Type</th>
              {extractKey(resourceMap, 1).map((tier) => (
                <th key={tier}>{tier}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {extractKey(resourceMap, 0).map((category) => (
              <tr key={category}>
                <th>{category}</th>
                {extractKey(resourceMap, 1).map((tier) => {
                  const key = `${category}:${tier}`;
                  const resource = resourceMap.get(key);
                  if (!resource) return <div></div>;
                  return (
                    <td
                      key={key}
                      style={{
                        backgroundColor: getBGColor(resource, props.minSet),
                      }}
                    >
                      <span style={{ fontSize: "20px" }}>
                        <img width={35} src={resource.iconUrl()} alt={key} />
                        <span style={{ margin: "3px" }}>x</span>
                        {resource.count ?? "no resource"}
                      </span>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        "loading..."
      )}
    </div>
  );
}
